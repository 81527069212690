$('[data-expand]').on('click', function(){
	$('body').toggleClass('expand');
	if( $('body').hasClass('expand') ){
		//$.get('/menu/show');
		$('.icon > i', this).removeClass('fa-chevron-circle-right').addClass('fa-chevron-circle-left');
	}else{
		//$.get('/menu/hide');
		$('.icon > i', this).removeClass('fa-chevron-circle-left').addClass('fa-chevron-circle-right');
	}
});
$('[data-map]').on('keyup', function(){
	var map = $(this).data('map');
	var location = encodeURIComponent( $(this).val() );
	if( location == '' ) location = 'Australia';
	$('iframe[data-map="'+map+'"]').attr('src', 'https://www.google.com/maps/embed/v1/place?q='+location+'&zoom=12&key=AIzaSyA6a73E8UjSrA5cWnReGbyyZ6b4HsPWKqw');
});
$('[data-auto]').on('keyup', function(){
	var type = $(this).data('auto');
	var complete = $('[data-autocomplete="' + type + '"]');
	$.post( '/' + type + '/auto', { 'auto': $(this).val() }, function( data ){
		if( data ){
			complete.html(data).show();
		}
	} );
});
$(document).on('click', '[data-action]', function(e){
	e.preventDefault();
	var link = $(this).attr('href');
	var anchor = $(this);
	$('i', anchor).removeClass('fa-flag fa-flag-o').addClass('fa-spin fa-spinner');
	$.get(link, function(data){
		if( data.state ){
			$('i', anchor).removeClass('fa-flag-o fa-spinner fa-spin').addClass('fa-flag');
		}else{
			$('i', anchor).removeClass('fa-flag fa-spinner fa-spin').addClass('fa-flag-o');
		}
	});
	anchor.blur();
});
$(document).on('click', '[data-removefill]', function(){
	var autocomplete = $(this).closest('[data-autocomplete]');
	var type = autocomplete.data('autocomplete');
	autocomplete.html('').hide().remove();
	$('[data-auto="'+type+'"]').removeAttr('data-auto');
});
$(document).on('click', '[data-autofill]', function(){
	$.each(this.attributes, function(){
		if( this.specified ){
			//console.log( this.name + ', ' + this.value );
			if( this.name.indexOf('data-') >= 0 ){
				var name = this.name.replace('data-','');
				console.log( name + ': ' + this.value );
				$('[name="'+name+'"]').val(this.value);
			}
		}
	});
	$(this).closest('[data-autocomplete]').html('').hide();
});
if( $('#installation').length ){
	var installationDate = $('#installation').fdatepicker({
		format: 'dd-mm-yyyy'
	}).data('datepicker');
}
$(document).on('click', '[data-modal]', function(){
	$('[data-reveal]').remove();
	var href = $(this).data('modal');
	$.get(href, function( data ){
		if( data ){
			$('body').append( $('<div class="reveal" id="modal" data-reveal>' + data + '</div>') );
			$('#modal', 'body').foundation('open');
		}
	});
});
$(document).on('click', '[data-dismiss]', function(){
	$(this).closest('[data-reveal]').foundation('close');
});
$(document).on('blur change', '[data-rating], [data-power], [data-type]', function(){
	var form = $(this).closest('[data-schedule]');
	var rating = $('[data-rating]', form);
	var ratings = rating.val().replace(/\s/g, '+').replace(/x/g, '*').replace( /[^0-9.+\*]/g, '' );
	if( ratings.indexOf('+') > 0 || ratings.indexOf('*') > 0 ){
		ratings = eval( ratings );
	}
	ratings = ratings * 1;
	var type = $('[data-type]', form);
	var power = $('[data-power]', form);
	var resistance = $('[data-resistance]', form);
	var length = $('[data-length]', form);
	power.val((power.val()>0)?power.val():ratings);
	resistance.val((resistance.val()>0)?resistance.val():Math.round((52900/power.val())*100)/100);
	length.val((type.val()=='onslab')?Math.round((power.val()/15)*100)/100:Math.round((power.val()/30)*100)/100);
});
$(document).on('click', '[data-calculate]', function(){
	var form = $(this).closest('[data-schedule]');
	var rating = $('[data-rating]', form);
	var ratings = rating.val().replace(/\s/g, '+').replace(/x/g, '*').replace( /[^0-9.+\*]/g, '' );
	if( ratings.indexOf('+') > 0 || ratings.indexOf('*') > 0 ){
		ratings = eval( ratings );
	}
	ratings = ratings * 1;
	var type = $('[data-type]', form);
	var power = $('[data-power]', form);
	var resistance = $('[data-resistance]', form);
	var length = $('[data-length]', form);
	power.val(ratings);
	resistance.val(Math.round((52900/power.val())*100)/100);
	length.val((type.val()=='onslab')?Math.round((power.val()/15)*100)/100:Math.round((power.val()/30)*100)/100);
});